<template>
    <div class="my">
        <!--  -->
        <div class="d_f myposi yh-pb20 yh-mt20">
            <div class="my_left">
                <div class="moren">
                    <span class="avatar">
                        <img v-if="userInfo.avatar" :src="userInfo.avatar || ''" alt />
                        <img v-else src="../../assets/image/shibai.png" alt />
                    </span>
                    <span class="username">{{userInfo.nickname || ''}}</span>
                </div>
                <div class="borde">
                    <div
                        :class="myindex == i ? 'myactive' : 'mylist'"
                        class="center_combo yh-cp"
                        @click="myclick(ite,i)"
                        v-for="(ite, i) in myList"
                        :key="i"
                    >
                        <span>
                            <img :src="myindex == i ? ite.icon2 : ite.icon" alt />
                        </span>
                        <span class="mytext">{{ ite.text }}</span>
                    </div>
                </div>
            </div>
            <div class="my_right flex-1">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
// import { info } from "@/config/api";
// import { setcache, getcache, eventBus } from "@/assets/js/methodfunction.js";
export default {
    data() {
        return {
            myList: [
                {
                    text: "我的课程",
                    icon: require("../../assets/image/xx-kecheng.png"),
                    icon2: require("../../assets/image/xx-kechengs.png"),
                    val: 0,
                    path: "/my/mycourses",
                },
                {
                    text: "实名认证",
                    icon: require("../../assets/image/xx-shiming.png"),
                    icon2: require("../../assets/image/xx-shimings.png"),
                    val: 1,
                    path: "/my/realname",
                },
                {
                    text: "我的档案",
                    icon: require("../../assets/image/xx-dangan.png"),
                    icon2: require("../../assets/image/xx-dangans.png"),
                    val: 2,
                    path: "/my/archives",
                },
                {
                    text: "在线题库",
                    icon: require("../../assets/image/xx-lianxi.png"),
                    icon2: require("../../assets/image/xx-lianxis.png"),
                    val: 3,
                    path: "/my/practice",
                },
                {
                    text: "在线考试",
                    icon: require("../../assets/image/xx-lianxi.png"),
                    icon2: require("../../assets/image/xx-lianxis.png"),
                    val: 3,
                    path: "/my/practice",
                },
                {
                    text: "在线客服",
                    icon: require("../../assets/image/xx-kefu.png"),
                    icon2: require("../../assets/image/xx-kefus.png"),
                    val: 4,
                    path: "/my/message",
                },
                {
                    text: "退出登录",
                    icon: require("../../assets/image/out.png"),
                    icon2: require("../../assets/image/out.png"),
                    val: 5,
                    path: "",
                },
            ],
            tabtext: "",
            myindex: 0,
            userinfo: {},
        };
    },
    created() {
        this.myindex =
            (sessionStorage.getItem("myindex") &&
                sessionStorage.getItem("myindex")) ||
            0;
        if (this.$route.path === "/my") {
            this.myclick(this.myindex);
        }
        this.getUserInfo();
    },
    watch: {
        $route(to) {
            if (to.fullPath === "/my") {
                this.myclick({},this.myindex);
            }
        },
    },
    methods: {
        myclick(ite,i) {
            if (ite.val == 5) {
                sessionStorage.removeItem("token");
                localStorage.removeItem("userInfo");
                this.$store.commit("upIsLogin", false);
                this.$router.push({path:'/login'})
                return
            }
            this.myindex = i;
            sessionStorage.setItem("myindex", i);
            if (this.$route.path == this.myList[i].path) return;
            this.$router.push({ path: this.myList[i].path });
        },
        //  获取个人信息
        getUserInfo() {
            this.$axios.get(this.$api.myinfo).then((res) => {
                if (res.code === 1) {
                    this.$store.commit("upUserInfo", res.data);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.myposi {
    display: flex;
    position: relative;
}
.my {
    width: 1200px;
    margin: 0 auto;
}
.place {
    font-size: 12px;
    padding: 20px 0;
    img {
        width: 24px;
        height: 24px;
    }
}
.my_left {
    background: #f8f8f8;
    min-height: 800px;
    .moren {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 90px 40px 90px;
        .avatar {
            display: inline-block;
            overflow: hidden;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
        .username {
            display: inline-block;
            margin-top: 20px;
            font-size: 18px;
        }
    }
    .mylist {
        display: flex;
        align-items: center;
        padding: 30px 30px;
        font-size: 16px;
        img {
            width: 30px;
            height: 30px;
        }
        span:nth-child(2) {
            display: inline-block;
            margin-left: 20px;
            font-size: 16px;
        }
    }
}
.mytext {
    display: inline-block;
    margin-left: 20px;
    font-size: 16px;
}
.my_right {
    width: 860px;
    margin-left: 26px;
    box-sizing: border-box;
    background: #ffffff;
    position: relative;
    border: 1px solid #f2f2f2;
}
.myactive {
    display: flex;
    align-items: center;
    padding: 30px 30px;
    background: #1678ff;
    color: #ffffff;
    font-size: 16px;
    img {
        width: 30px;
        height: 30px;
    }
    span:nth-child(2) {
        display: inline-block;
        margin-left: 20px;
        font-size: 16px;
    }
}
.borde {
    margin-bottom: 130px;
}
</style>
