<template>
	<div id="app">
		<Header />
		<router-view />
		<Footer />
		<authentication ref="authentication"></authentication>
	</div>
</template>
<script>
import authentication from "@/components/authentication";
export default {
	name: "Layout",
	components: {
		authentication
	},
	data() {
		return {};
	},
	watch: {
		needRenzheng() {
			if (this.needRenzheng) {
				this.$refs.authentication.show();
			}
		}
	},
	created() {},
	mounted() {},
	computed: {
		needRenzheng() {
			return this.$store.getters.needRenzheng;
		}
	},
	methods: {}
};
</script>

<style lang="scss"></style>
