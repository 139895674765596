<template>
    <div class="recently yh-w100">
        <div class="real_title level_align flex_between">
            <span>培训工种</span>
            <el-select
                class="yh-mr10"
                :value="actClass.name"
                v-model="actClass"
                value-key="id"
                @change="changeSelect"
                size="small"
                placeholder="请选择"
            >
                <el-option
                    v-for="(item,index) in classesList"
                    :key="index"
                    :label="item.name"
                    :value="item"
                ></el-option>
            </el-select>
        </div>

        <div class="yh-pd30">
            <div class="yh-bcF5 yh-br8 yh-pd30 yh-lh36 yh-bsf5 yh-fz16 yh-fc999">
                <div class="level_align">
                    <div class="level_align flex-1">
                        <span class="yh-fc333 yh-mr20">姓名</span>
                        {{ info.uname || "- -" }}
                    </div>
                    <div class="level_align w60">
                        <span class="yh-fc333 yh-mr20">人脸识别</span>
                        {{ info.auth || "- -" }}
                    </div>
                </div>

                <div class="level_align">
                    <div class="level_align flex-1">
                        <span class="yh-fc333 yh-mr20">联系电话</span>
                        {{ info.mobile || "- -" }}
                    </div>
                    <div class="level_align w60">
                        <span class="yh-fc333 yh-mr20">学习形式</span>
                        {{ info.type || "- -" }}
                    </div>
                </div>
                <div class="level_align">
                    <div class="level_align flex-1 yh-tl">
                        <span class="yh-fc333 yh-mr20">所在班级</span>
                        {{ info.cname || "- -" }}
                    </div>
                    <div class="level_align w60 yh-tl">
                        <span class="yh-fc333 yh-mr20">培训工种</span>
                        {{ info.name || "- -" }}
                    </div>
                </div>
                <div class="level_align">
                    <div class="level_align flex-1">
                        <span class="yh-fc333 yh-mr20">完成课程学时</span>
                        {{ info.hours || "- -" }}
                    </div>
                    <div class="level_align w60">
                        <span class="yh-fc333 yh-mr20">培训等级</span>
                        {{ info.level || "- -" }}
                    </div>
                </div>
                <div class="level_align">
                    <div class="level_align">
                        <span class="yh-fc333 yh-mr20">身份证</span>
                        {{ info.code || "- -" }}
                    </div>
                </div>
                <!-- <div class="level_align">
                    <div class="level_align">
                        <span class="yh-fc333 yh-mr20">学习所属区（县）主管部门</span>
                        {{ info.area || "- -" }}
                    </div>
                </div> -->
                <div class="level_align">
                    <div class="level_align">
                        <span class="yh-fc333 yh-mr20">{{ info.dw || '' }}</span>
                        {{ info.dx || "" }}
                    </div>
                </div>
            </div>
        </div>

        <p class="yh-fc666 yh-ptb0lr30">章节目录</p>
        <div class="yh-pd30">
            <div
                @click="pageJumps('chapter',{'name':item.name,id:item.id})"
                class="yh-bcF5 yh-mb20 yh-br12 yh-pd20 yh-lh30 yh-fc333 yh-bsf5 yh-fz16 yh-bbox yh-cp"
                v-for="(item, index) in list"
                :key="index"
            >
                <div class="level_align">
                    <div class="level_align">
                        <span class="yh-fc666 yh-mr20">章节名称</span>
                        {{ item.name }}
                    </div>
                </div>
                <div class="level_align yh-fc999">
                    <div class="level_align flex-1">
                        <span class="yh-fc666 yh-mr20">视频总时长</span>
                        {{ item.hours || 0 }}
                    </div>
                    <div class="level_align flex-1">
                        <span class="yh-fc666 yh-mr20">已学时长</span>
                        {{ item.uhours || 0 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            classesList: [], //所有班级
            actClass: {}, // 所选班级
            info: {}, // 页面数据
            list: [], // 章节列表
        };
    },
    created() {
        this.getClasses();
    },
    methods: {
        changeSelect() {
            this.getMyRecord();
        },
        // 获取获取我的档案
        getMyRecord() {
            if (!this.actClass.id) {
                return this.$message({
                    message: "请先报名课程",
                    type: "warning",
                });
            }
            this.$axios
                .post(this.$api.MyRecord, {
                    id: this.actClass.id,
                    course_id: this.actClass.course_id,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.info = res.data;
                        if (res.data.info.length) {
                            res.data.info.map((item) => {
                                item.hours = parseInt(item.hours / 60);
                                item.uhours = parseInt(item.hours / 60);
                            });
                        }
                        this.list = res.data.info;
                    }
                });
        },
        // 获取获取我的所有班级
        getClasses() {
            this.$axios.post(this.$api.classes).then((res) => {
                if (res.code == 1) {
                    this.classesList = res.data;
                    if (res.data.length) {
                        this.actClass = res.data[0];
                        this.getMyRecord();
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.real_title {
    background: #f8f8f8;
    span {
        padding: 10px 0 10px 20px;
        display: inline-block;
        border-left: 5px solid #1678ff;
        font-size: 18px;
    }
}
.w60 {
    width: 50%;
}
</style>
